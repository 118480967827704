import React from "react"


const SecondPage = () => (
  // <Layout>
  //   <SEO title="Page two" />
  <h1>Hi from the second page</h1>

)

export default SecondPage
